import React, { useEffect, useState } from "react";
import { useLanguagesContext } from "../../contexts/LanguagesContext";
import Select from 'react-select';
import Button from "../general/Button";
import useDecks from "../../hooks/useDecks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/pro-solid-svg-icons";
import useAI from "../../hooks/useAI";

const MultiLingualDeckTable = ({deck, dbCards}) => {
  const [columns, setColumns] = useState(["en"]);
  const [rows, setRows] = useState([{}]);
  const [saving, setSaving] = useState(false);
  const [aiWorking, setAiWorking] = useState(false);
  const [fillInMissingWorking, setFillInMissingWorking] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [showAIPrompt, setShowAIPrompt] = useState(false);
  const {saveChangesToDeck, 
    addItemToDeck, 
    updateMultilingualItemInDeck, 
    deleteDeckCard
  } = useDecks();

  const {generateAICardsForDeck} = useAI();

  useEffect(()=> {
    if (deck){
        if (deck.hasOwnProperty("languages")){
            setColumns(deck.languages);
        }
    };
  },[deck]);

  useEffect(() => {
    if (dbCards){
        let rows_ = [];
        dbCards.forEach(card=>{
            console.log("card: ", card);
            let row = {card_id: card["id"]};
            deck.languages.forEach((lang, index)=>{
                row[lang] = card["terms"].hasOwnProperty(lang) ? card["terms"][lang] : null;
            }); 
            rows_.push(row);
        });
        setRows(rows_);
    }
    
  }, [dbCards]);

  const {allLanguages, sourceLanguageOptions} = useLanguagesContext();

  const addColumn = () => {
    setColumns([...columns, null]);
  };

  const addRow = () => {
    setRows([...rows, {}]);
  };

  const handleInputChange = (rowIndex, language, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][language] = value;
    setRows(updatedRows);
  };

  const languageOnChange = (option, colIndex) => {
    const updatedColumns = [...columns];
    updatedColumns[colIndex] = option.value;
    setColumns(updatedColumns);
  };

  const saveCardsToDatabase = async () => {
    let updatedRows = [...rows]; // Work with a copy to avoid race conditions

    for (let i = 0; i < updatedRows.length; i++) {
        let row = updatedRows[i];

        let terms = {};
        let hasAValue = false;

        columns.forEach((lang) => {
            if (row[lang]) hasAValue = true;
            terms[lang] = row[lang] || null;
        });

        if (!hasAValue) continue; // Skip empty rows

        if (!row.card_id) {
            // Add new card
            let res = await addItemToDeck({ deck_id: deck.id, multilingual: true, multilingualTerms: terms });

            if (res?.id) {
                updatedRows[i] = { ...row, card_id: res.id };
            }
        } else {
            // Update existing card
            await updateMultilingualItemInDeck({ cardDocId: row.card_id, multilingualTerms: terms });
        }
    }

    setRows(updatedRows); // Only update state once at the end
};


  const saveChangesClick = async () => {
    console.log("Columns: ", columns);
    console.log("Rows: ", rows);
    setSaving(true);
    //update languages in deck
    await saveChangesToDeck({languages: columns.filter((col)=>col !== null), deckId: deck.id});
    // add or update the cards
    await saveCardsToDatabase();
    setSaving(false);
  };

  const toggleAIAssistant = () => {
    setShowAIPrompt(!showAIPrompt);
  }

  const generateAICardsClick = async ({prompt=null}) => {
    setAiWorking(true);
    console.log("Rows and prompt: ", rows, JSON.stringify(rows), prompt);
    let results = await generateAICardsForDeck({
        prompt: (!prompt ? `Make cards that correspond to this topic provided by the user: ${aiPrompt}.
        The user asked for the languages ${columns.join(", ")}. If you fill out existing values, make sure that you keep the original keys as well for the other languages along with the data. Return the full json object.
        (but if some languages are missing translations you should add them and preserve the existing items) Here is the json the user submitted: ${JSON.stringify(rows ? rows : [])}`
        :
        prompt)
    });


    console.log("Results: ", results);
    if (Array.isArray(results)) {
        let newRows = [...rows];

        results.forEach((card) => {
            let row = {};
            columns.forEach((lang) => {
                row[lang] = card.hasOwnProperty(lang) ? card[lang] : null;
            });

            // Check the value of the first column to see if the row already exists and merge if it does
            let existingRowIndex = newRows.findIndex((r) => r[columns[0]] === row[columns[0]]);

            if (existingRowIndex !== -1) {
                // Merge translations for existing row
                let mergedRow = { ...newRows[existingRowIndex] };
                columns.forEach((lang) => {
                    if (!mergedRow[lang] && row[lang]) {
                        mergedRow[lang] = row[lang];
                    }
                });
                newRows[existingRowIndex] = mergedRow;
            } else {
                newRows.push(row);
            }
        });
        //capitliaze first letter
        newRows.forEach((row)=>{
            columns.forEach((lang)=>{
                if (row.hasOwnProperty(lang)){
                    row[lang] = row[lang].charAt(0).toUpperCase() + row[lang].slice(1);
                }
            });
        });
        setRows(newRows);
    }

    setAiWorking(false);
};


  const deleteRow = async (row, rowIndex) => {
    let newRows = [...rows];
    newRows.splice(rowIndex, 1);
    setRows(newRows);
    console.log("deleting row: ", row);
    if (row.hasOwnProperty("card_id")){
        //delete card from database
        await deleteDeckCard({cardDocId: row.card_id});
    }
  };

  const addDefaultLanguages = () => {
    const list = ["en", "fr", "no", "es", "zh", "de", "it", "nl", "da", "sv", "pt", "fi", "ro", "is", "ru"];
    // add if not already present
    const newColumns = [...columns];
    list.forEach((language) => {
        if (!newColumns.includes(language)){
            newColumns.push(language);
        }
    });
    setColumns(newColumns);
  }

  const capitalizeClick = () => {
    let newRows = [...rows];
    newRows.forEach((row)=>{
        columns.forEach((lang)=>{
            if (row.hasOwnProperty(lang)){
                row[lang] = row[lang].charAt(0).toUpperCase() + row[lang].slice(1);
            }
        });
    });
    setRows(newRows);
  }

  const deleteDuplicateRows = async () => {
    let seen = new Set();
    let newRows = [];
    let deletedRows = [];

    for (const row of rows) {
        let firstCol = row[columns[0]];
        if (!seen.has(firstCol)) {
            seen.add(firstCol);
            newRows.push(row);
        } else {
            deletedRows.push(row);
        }
    }

    // Delete rows from the database
    for (const row of deletedRows) {
        if (row.hasOwnProperty("card_id")) {
            console.log("Deleting card: ", row);
            await deleteDeckCard({ cardDocId: row.card_id });
        }
    }

    // Update the state AFTER deletion
    setRows(newRows);
};

  const fillInMissingTranslationsWithAI = async () => {
    let prompt = `Fill in missing translations for the existing cards in the languages that are missing based on the languages ${columns.join(", ")}:  ${JSON.stringify(rows)}.  The user asked for the languages ${columns.join(", ")}. If you fill out existing values, make sure that you keep the original keys as well for the other languages along with the data. Return the full json object.`;
    setFillInMissingWorking(true);
    await generateAICardsClick({prompt:prompt});
    setFillInMissingWorking(false);
  }


  return (
    <div className="flex flex-col gap-12">

      <div className="flex gap-3 mb-4 justify-center">
        <button
          onClick={addColumn}
          className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition"
        >
          Add language
        </button>
        <button
          onClick={addRow}
          className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition"
        >
          Add card
        </button>
        <button
          onClick={addDefaultLanguages}
          className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition"
        >
          Add default languages
        </button>
        <button onClick={deleteDuplicateRows} className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition">
            Delete duplicates
        </button>
        <button
          onClick={capitalizeClick}
          className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition"
        >
          Capitalize
        </button>
        <button onClick={toggleAIAssistant} className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg shadow-md hover:bg-opacity-90 transition">
            {showAIPrompt ? "Close AI assistant" : "Generate with AI"}
        </button>
        <button onClick={fillInMissingTranslationsWithAI} className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg shadow-md hover:bg-opacity-90 transition">
            {fillInMissingWorking ? "Generating..." : "Fill in missing translations with AI"}
        </button>

      </div>
      {showAIPrompt && (
  <div className="bg-white dark:bg-gray-800 rounded-xl p-5 shadow-lg flex flex-col place-items-center gap-3">
    <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
      🤖 AI Assistant
    </h2>
        <input
        type="text"
        value={aiPrompt}
        onChange={(e) => setAiPrompt(e.target.value)}
        placeholder="Describe what you need..."
        className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg outline-none focus:ring-2 focus:ring-persian-green focus:border-transparent transition"
        />
        <button onClick={generateAICardsClick} className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-opacity-90 transition">
            {aiWorking ? <FontAwesomeIcon icon={faSpinner} spin /> : "Let AI do its thing..."}
        </button>
  </div>
)}


      <div className="overflow-x-auto">
        <table className="w-full border border-gray-300 rounded-lg overflow-hidden shadow-sm min-h-96">
          <thead>
            <tr className="bg-persian-green text-gray-700">
              {columns.map((language, index) => (
                <th key={index} className="border border-gray-300 px-4 py-3 text-left font-semibold">
                  <Select
                    value={sourceLanguageOptions[1][language]}
                    onChange={(option)=>languageOnChange(option, index)}
                    options={sourceLanguageOptions[0]}
                    autoFocus={false}
                    className="w-48 text-gray-500 dark:text-gray-300 text-base"
                    />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-100 transition relative">
                {columns.map((language, colIndex) => (
                  <td key={colIndex} className="border  border-gray-300 px-4 py-2">
                    <div className="flex flex-row place-items-center gap-2">
                    {colIndex === 0 && 
                    <span className="text-xs text-gray-500">
                      {rowIndex}
                    </span>
}
                    <input
                      type="text"
                      value={row[language] || ""}
                      onChange={(e) =>
                        handleInputChange(rowIndex, language, e.target.value)
                      }
                      className="w-full px-2 py-1 border border-gray-300 rounded-md outline-none focus:border-persian-green"
                    />
                    </div>
                  </td>
                ))}
                <div onClick={()=>deleteRow(row, rowIndex)} className="absolute right-0 top-1 text-red-500 cursor-pointer text-sm ">
                    <FontAwesomeIcon icon={faTrash} />
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row justify-center place-items-center gap-8">      
        <Button onClick={saveChangesClick} text="Save changes" color="green" />
        {saving && <FontAwesomeIcon icon={faSpinner} spin />}
      </div>
    </div>
  );
};

export default MultiLingualDeckTable;
