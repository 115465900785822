import React, { useState } from 'react';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import Select from 'react-select';
import Button from '../general/Button';
import useCollections from '../../hooks/useCollections';

const CollectionLanguages = ({collection}) => {

    const [chosenLanguages, setChosenLanguages] = useState(collection.languages);
    const {allLanguages, sourceLanguageOptions} = useLanguagesContext();
    const {saveChangesToCollection} = useCollections();
    const [saving, setSaving] = useState(false);

    const languageOnChange = (option, index) => {
        let newLanguages = [...chosenLanguages];
        newLanguages[index] = option.value;
        setChosenLanguages(newLanguages);
    }

    const saveChangesClick = async () => {
        setSaving(true);
        await saveChangesToCollection({languages: chosenLanguages, docId: collection.docId});
        setSaving(false);
    }

    const addDefaultLanguages = () => {
        const list = ["en", "fr", "no", "es", "zh", "de", "it", "nl", "da", "sv", "pt", "fi", "ro", "is", "ru"];
       // add if not already present
        const newLanguages = [...chosenLanguages];
        list.forEach((language) => {
            if (!newLanguages.includes(language)){
                newLanguages.push(language);
            }
        });
        setChosenLanguages(newLanguages);
    }

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-3">
                    <h1>Languages in collection</h1>
                    <div className="flex flex-row justify-center gap-5">
                        <button
                            onClick={addDefaultLanguages}
                            className="px-4 py-2 bg-persian-green text-white rounded-lg shadow-md hover:bg-opacity-90 transition"
                        >
                            Add default languages
                        </button>
                    </div>
                </div>
                {chosenLanguages &&
                    <>
                    {chosenLanguages.length === 0 ?
                        <div className="text-center">
                            No languages added yet
                        </div>
                    :
                    <div className="grid grid-cols-8">
                        {chosenLanguages.map((language, index) => (
                            <div key={index} className="flex flex-col gap-2">
                                <div className="text-xs muted-text">
                                    Language #{index+1}
                                </div>
                                <Select 
                                    value={sourceLanguageOptions[1][language]}
                                    onChange={(option)=>languageOnChange(option, index)}
                                    options={sourceLanguageOptions[0]}
                                    autoFocus={false}
                                    className="w-48 text-gray-500 dark:text-gray-300 text-base"
                                />
                            </div>
                        ))}
                    </div>
                    }
                    <div className="flex flex-row justify-center gap-5">
                        <Button text="Add Language" color="charcoal" onClick={()=>setChosenLanguages([...chosenLanguages, ""])} />
                        <Button text={!saving ? "Save Changes" : "Saving..."} color="green" onClick={saveChangesClick} />
                    </div>
                    </>
                }

            </div>
        </>
    )

}

export default CollectionLanguages;